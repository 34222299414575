import MaterialsService, { updateIndex } from "@/services/MaterialsService";

export default {
    state() {
        return {
            material: "",
            materialsData: "",
            success: ""
        }
    },

    getters: {
        getMaterialName: (state) => state.material.name,
        getMaterialColor: (state) => state.material.color,
        getMaterialDisciplineId: (state) => state.material.disciplineId,
    },

    mutations: {
        setMaterialData(state, data) {
            state.material.name = data.name;
            state.material.color = data.color;
            state.material.disciplineId = data.disciplineId;
            state.material.visible = data.visible;
        },
        resetMaterial(state) {
            state.material = {
                name: "",
                color: "",
                disciplineId: "",
            };
        },
        setMaterialsData(state, data) {
            state.materialsData = data
        },
        success: (state, message) => {
            state.success = message;
        },
    },

    actions: {
        async createMaterial({ commit }, payload) {
            try {
                const response = await MaterialsService.createMaterial(payload.material, payload.userToken);

                commit('resetMaterial');

                return response.data;
            } catch (error) {
                console.error('Erro ao adicionar material:', error);

                throw error;
            }
        },

        async findMaterial({ commit }, payload) {
            try {
                const response = await MaterialsService.findMaterial(payload.materialId, payload.userToken);

                commit('setMaterialData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar material:', error);

                throw new Error('Não foi possível consultar material. Por favor, tente novamente.');
            }
        },

        async updateMaterial({ commit }, payload) {
            try {
                const response = await MaterialsService.updateMaterial(payload.material, payload.userToken);

                commit('setMaterialData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar material:', error);

                throw error;
            }
        },

        async deleteMaterial({ commit }, payload) {
            try {
                const response = await MaterialsService.deleteMaterial(payload.materialId, payload.userToken);

                commit('resetMaterial');

                return response.data;
            } catch (error) {
                console.error('Erro ao deletar material:', error);

                throw new Error('Não foi possível deletar a material. Por favor, tente novamente.');
            }
        },

        async duplicateMaterial({ commit }, payload) {
            try {
                const response = await MaterialsService.duplicateMaterial(payload.materialId, payload.userToken);

                commit('resetMaterial');

                return response.data;
            } catch (error) {
                console.error('Erro ao duplicar material:', error);

                throw error ;
            }
        },

        async getMaterials({ commit }, payload) {
            try {
                const response = await MaterialsService.getMaterials(payload.data, payload.userToken);

                commit('setMaterialsData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar materiais:', error);

                throw new Error('Não foi possível consultar as materiais. Por favor, tente novamente.');
            }
        },

        async updateIndex({ commit }, payload) {
            try {
                const response = await updateIndex(payload.data, payload.userToken);

                commit('success', 'Índice atualizado com sucesso!');

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar índice:', error);

                throw new Error('Não foi possível atualizar o índice. Por favor, tente novamente.');
            }
        }
    }
}
