import MaterialsApiService from "./api/MaterialsApiService";

export const createDiscipline = async (payload, userToken) => {
    try {
      const response = await MaterialsApiService.post('/disciplines', payload, {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Erro ao adicionar disciplina', error);
  
      throw error;
    }
  };
  
  export const findDiscipline = async (disciplineId, userToken) => {
    try {
      const response = await MaterialsApiService.get('/disciplines/' + disciplineId, {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Erro ao consultar disciplina', error);
  
      throw error;
    }
  };
  
  export const updateDiscipline = async (payload, userToken) => {
    try {
      const response = await MaterialsApiService.put('/disciplines/' + payload.disciplineId, payload, {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Erro ao atualizar disciplina', error);
  
      throw error;
    }
  };
  
  export const deleteDiscipline = async (disciplineId, userToken) => {
    try {
      const response = await MaterialsApiService.delete('/disciplines/' + disciplineId, {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar disciplina', error);
  
      throw error;
    }
  };
  
  export const getDisciplines = async (payload, userToken) => {
    try {
      let url = '/disciplines?';
  
      if (payload.search) {
        url += `search=${payload.search}&`;
      }

      if (payload.tagId) {
        url += `tag_id=${payload.tagId}&`;
      }

      if (payload.type) {
        url += `type=${payload.type}&`;
      }

      if (payload.consumerId) {
        url += `consumer_id=${payload.consumerId}&`;
      }

      if (payload.page) {
        url += `page=${payload.page}&`;
      }

      if (payload.perPage) {
        url += `per_page=${payload.perPage}&`;
      }
  
      const response = await MaterialsApiService.get(url.slice(0, -1), {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Erro ao consultar disciplinas:', error);
      throw error;
    }
  }
  
  export const insertTags = async (payload, userToken) => {
    try {
      const response = await MaterialsApiService.post(`/disciplines/${payload.disciplineId}/discipline-tag`, {discipline_tag_ids : payload.disciplineTagIds}, {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Erro ao inserir tag na disciplina', error);
  
      throw error;
    }
  };

  export const removeTag = async (payload, userToken) => {
    try {
      const response = await MaterialsApiService.post(`/disciplines/${payload.disciplineId}/discipline-tag/remove`, {discipline_tag_id : payload.disciplineTagId}, {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Erro ao remover tag na disciplina', error);
  
      throw error;
    }
  };

  export const duplicateDiscipline = async (disciplineId, userToken) => {
    try {
      const response = await MaterialsApiService.post(`/disciplines/${disciplineId}/duplicate`, [], {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      });

      return response.data;
    } catch  (error) {
      console.log('Erro ao duplicar disciplina', error);

      throw error;
    }
  }
  
  export default {
    createDiscipline,
    findDiscipline,
    updateDiscipline,
    deleteDiscipline,
    getDisciplines,
    insertTags,
    removeTag,
    duplicateDiscipline
  }