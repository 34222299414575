import GradesService from "@/services/GradesService";

export default {
    state() {
        return {
            grade: null,
            gradesData: ""
        }
    },

    getters: {
        getGrade: (state) => state.grade,
        getGradesData: (state) => state.gradesData
    },

    mutations: {
        setGradeData(state, data) {
            state.grade = data;
        },
        setGradesData(state, data) {
            state.gradesData = data
        }
    },

    actions: {
        async getGrades({ commit }, payload) {
            try {
                const response = await GradesService.getGrades(payload.data, payload.userToken);

                commit('setGradesData', response);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar notas', error);

                throw error;
            }
        },

        async updateGrade({ commit }, payload) {
            try {
                const response = await GradesService.updateGrade(payload, payload.userToken);

                commit('setGradeData', response);

                return response;
            } catch (error) {
                console.error('Erro ao atualizar nota', error);

                throw error;
            }
        }
    }
}
