import AuthService from './api/AuthService';

export const getConsumerSettings = async (userToken) => {
    try {
        const response = await AuthService.get('/consumer-settings', {
            headers: {
                'Authorization': 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao consultar configurações do consumidor', error);

        throw error;
    }
}

export const updateConsumerSettings = async (payload, userToken) => {
    try {
        const response = await AuthService.post('/consumer-settings/' + payload.consumerSettingsId + '/update', payload, {
            headers: {
                'Authorization': 'Bearer ' + userToken,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar configurações do consumidor', error);

        throw error;
    }
}

export default {
    getConsumerSettings,
    updateConsumerSettings
}