import { createStore } from "vuex";
import loginModule from "@/store/loginModule";
import companiesModule from "@/store/companiesModule";
import usersModule from "./usersModule";
import disciplinesModule from "./disciplinesModule";
import createPersistedState from "vuex-persistedstate";
import adminModule from "./adminModule";
import disciplineTagsModule from "./disciplineTagsModule";
import disciplineTopicsModule from "./disciplineTopicsModule";
import materialItemsModule from "./materialItemsModule";
import materialsModule from "./materialsModule";
import logoutModule from "./logoutModule";
import quizzesModule from "./quizzesModule";
import feedbacksModule from "./feedbacksModule";
import questionOptionsModule from "./questionOptionsModule";
import materialItemsSettingsModule from "./materialItemSettingsModule";
import attemptModule from "./attemptModule";
import disciplineInscriptionModule from "./disciplineInscriptionModule";
import loadingModule from "@/store/loadingModule.js";
import ltiConsumersModule from "./ltiConsumersModule";
import consumerDisciplinesModule from "./consumerDisciplinesModule";
import consumersModule from "./consumersModule";
import consumerSettingsModule from "./consumerSettingsModule";
import suppliersModule from "./suppliersModule";
import gradesModule from "./gradesModule";

export default createStore({
  modules: {
    login: loginModule,
    companies: companiesModule,
    users: usersModule,
    disciplines: disciplinesModule,
    disciplineTags: disciplineTagsModule,
    disciplineTopics: disciplineTopicsModule,
    material: materialsModule,
    materialItem: materialItemsModule,
    admin: adminModule,
    logout: logoutModule,
    quizzes: quizzesModule,
    feedbacks: feedbacksModule,
    questionOptions: questionOptionsModule,
    materialItemSettings: materialItemsSettingsModule,
    attempt: attemptModule,
    disciplineInscription: disciplineInscriptionModule,
    loading: loadingModule,
    ltiConsumers: ltiConsumersModule,
    consumerDisciplines: consumerDisciplinesModule,
    consumers: consumersModule,
    consumerSettings: consumerSettingsModule,
    suppliers: suppliersModule,
    grades: gradesModule,
  },
  
  plugins: [createPersistedState()],

});
